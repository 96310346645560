import { BackgroundJobPayload } from 'interfaces';
import { Dispatch } from 'react';
import { useSelector } from 'react-redux';
import { createSubscriptiveSlice, ResponsePayload } from 'slices/subscriptive';
import { emitAsync } from '../utils/socket';
import { getSocket } from './connection';
import { setError } from './errors';

const {
  select,
  selectDictionary,
  unsubscribe,
  resetResource,
  reducer,
  reconnect,
  onPublish,
  subscribe,
  selectResourceList,
  slice,
  initialState,
} = createSubscriptiveSlice({
  name: 'backgroundJobs',
  payloadType: BackgroundJobPayload,
  deletedFilterFn(resource): boolean {
    return resource.deletedAt != null;
  },
  reducers: {},
  idProp: 'id',
});

export default slice.reducer;

export { unsubscribe, reducer, reconnect, resetResource, onPublish, subscribe, slice, initialState };

export const useBackgroundJobs = () => {
  const { loading: jobsLoading, subscribed: jobsSubscribed } = useSelector(select);
  const jobs = useSelector(selectResourceList) as BackgroundJobPayload[];
  const jobsById = useSelector(selectDictionary) as Record<string, BackgroundJobPayload>;
  return {
    jobs,
    jobsById,
    jobsLoading,
    jobsSubscribed,
  };
};

export const createDriverEventsRemovalJob =
  (companyId: string, driverId: string, from: string, to: string) => async (dispatch: Dispatch<any>) => {
    const socket = getSocket();

    const response = await emitAsync<ResponsePayload>(socket, 'backgroundJobs:create:driver-events-removal', {
      companyId,
      driverId,
      from,
      to,
    });

    if (response.status !== 'ok') {
      dispatch(setError({ status: response.status, msg: response.msg }));
    }

    return response;
  };
