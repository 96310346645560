import { SubscriptionLockPayload } from 'interfaces';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { createSubscriptiveSlice, ResponsePayload } from 'slices/subscriptive';
import { Socket } from 'socket.io-client';
import { RootState } from 'store/reducer';
import { emitAsync } from 'utils/socket';
import { v4 } from 'uuid';
import { setError } from './errors';

export class CreateSubscriptionLockPayload {
  clientResourceId: string;
  message?: string | null;
  companyId: string;
  driverId?: string;
}

export class UpdateSubscriptionLockPayload extends CreateSubscriptionLockPayload {
  id: string;
}

const {
  select,
  selectDictionary,
  unsubscribe,
  reducer,
  reconnect,
  onPublish,
  subscribe,
  selectResourceList,
  slice,
  initialState,
} = createSubscriptiveSlice({
  name: 'subscriptionLocks',
  payloadType: SubscriptionLockPayload,
  deletedFilterFn(resource): boolean {
    return resource.deletedAt != null;
  },
  reducers: {},
  idProp: 'clientResourceId',
});

const create =
  (subscriptionLock: CreateSubscriptionLockPayload) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<ResponsePayload<null | undefined>> => {
    const socket = getSocket();
    const clientResourceId = v4();
    const response = await emitAsync<ResponsePayload<null | undefined>>(socket, `subscriptionLock:create`, {
      ...subscriptionLock,
      clientResourceId: subscriptionLock.clientResourceId || clientResourceId,
    });

    if (response.status !== 'ok') {
      dispatch(setError({ status: response.status, msg: response.msg }));
    }

    return response;
  };

const update =
  (subscriptionLock: UpdateSubscriptionLockPayload, existingSubscriptionLock: SubscriptionLockPayload) =>
  async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket): Promise<ResponsePayload> => {
    const socket = getSocket();
    if (!!existingSubscriptionLock) {
      const optimisticSubscriptionLock: SubscriptionLockPayload = {
        ...existingSubscriptionLock,
        ...subscriptionLock,
        version: existingSubscriptionLock.version,
        loading: true,
      };
      await dispatch(onPublish([optimisticSubscriptionLock]));

      const response = await emitAsync<ResponsePayload>(socket, `subscriptionLock:update`, subscriptionLock);
      if (response.status !== 'ok') {
        dispatch(onPublish([existingSubscriptionLock]));
        dispatch(setError({ status: response.status, msg: response.msg }));
      }
      return response;
    } else {
      const error = {
        status: 'error',
        msg: 'Synchronization error',
      };
      dispatch(setError(error));

      return error;
    }
  };

const deleteSubscriptionLock =
  (existingSubscriptionLock: SubscriptionLockPayload) =>
  async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket): Promise<ResponsePayload> => {
    const socket = getSocket();
    if (!!existingSubscriptionLock) {
      const optimisticSubscriptionLock: SubscriptionLockPayload = {
        ...existingSubscriptionLock,
        deletedAt: moment().toISOString(),
        loading: true,
      };
      await dispatch(onPublish([optimisticSubscriptionLock]));

      const response = await emitAsync<ResponsePayload>(socket, `subscriptionLock:delete`, {
        id: existingSubscriptionLock.id,
      });
      if (response.status !== 'ok') {
        dispatch(onPublish([existingSubscriptionLock]));
        dispatch(setError({ status: response.status, msg: response.msg }));
      }
      return response;
    } else {
      const error = {
        status: 'error',
        msg: 'Synchronization error',
      };
      dispatch(setError(error));

      return error;
    }
  };

const disableSubscriptionLock =
  (existingSubscriptionLock: SubscriptionLockPayload, state: boolean) =>
  async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket): Promise<ResponsePayload> => {
    const socket = getSocket();
    if (!!existingSubscriptionLock) {
      const optimisticSubscriptionLock: SubscriptionLockPayload = {
        ...existingSubscriptionLock,
        loading: true,
        disabled: state,
      };
      await dispatch(onPublish([optimisticSubscriptionLock]));

      const response = await emitAsync<ResponsePayload>(socket, `subscriptionLock:disable`, {
        id: existingSubscriptionLock.id,
        state,
      });
      if (response.status !== 'ok') {
        dispatch(onPublish([existingSubscriptionLock]));
        dispatch(setError({ status: response.status, msg: response.msg }));
      }
      return response;
    } else {
      const error = {
        status: 'error',
        msg: 'Synchronization error',
      };
      dispatch(setError(error));

      return error;
    }
  };

const { setLoading } = slice.actions;

export default slice.reducer;

export {
  unsubscribe,
  reducer,
  reconnect,
  onPublish,
  subscribe,
  slice,
  initialState,
  update,
  create,
  deleteSubscriptionLock,
  disableSubscriptionLock,
};

export const useSubscriptionLocks = () => {
  const { loading: subscriptionLocksLoading, subscribed: subscriptionLocksSubscribed } = useSelector(select);
  const subscriptionLocks = useSelector(selectResourceList) as SubscriptionLockPayload[];
  const subscriptionLocksById = useSelector(selectDictionary) as Record<string, SubscriptionLockPayload>;

  return {
    subscriptionLocks,
    subscriptionLocksById,
    subscriptionLocksLoading,
    subscriptionLocksSubscribed,
  };
};
