import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TokenPayload } from 'interfaces';
import { Dispatch } from 'redux';
import { ResponsePayload } from 'slices/subscriptive';
import { io, Socket } from 'socket.io-client';
import { RootState } from 'store/reducer';
import { emitAsync } from 'utils/socket';

export let socket: Socket;

export const getSocket = () => socket;

export interface CredentialsPayload {
  username: string;
  password: string;
}

interface ConnectionState {
  loading: boolean;
  token: null | TokenPayload;
  authenticated: boolean;
}

const initialState: ConnectionState = {
  loading: true,
  token: null,
  authenticated: false,
};

export const connectionSlice = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    setLoading: (state, { payload: loading }: PayloadAction<boolean>) => {
      return {
        ...state,
        loading,
      };
    },
    setAuthenticated: (state, { payload: authenticated }: PayloadAction<boolean>) => {
      return {
        ...state,
        authenticated,
      };
    },
    setToken: (state, { payload: token }: PayloadAction<TokenPayload | null>) => {
      return {
        ...state,
        token,
      };
    },
  },
});

export const { setToken, setLoading, setAuthenticated } = connectionSlice.actions;

export const obtainTokenAsync =
  (credentials: CredentialsPayload) =>
  async (dispatch: Dispatch<any>): Promise<ResponsePayload<any>> => {
    dispatch(setLoading(true));
    const response = await emitAsync<ResponsePayload<TokenPayload>>(socket, 'token:create', credentials);
    if (response.status === 'ok' && response.data) {
      localStorage.setItem('token', JSON.stringify(response.data));
      dispatch(setToken(response.data));
    }
    dispatch(setLoading(false));

    return response;
  };

export const tryAuthenticateAsync =
  () =>
  async (dispatch: Dispatch<any>, getState: () => RootState): Promise<ResponsePayload<any>> => {
    let token = selectConnection(getState())?.token || JSON.parse(localStorage.getItem('token') || 'null');
    dispatch(setLoading(true));
    if (token) {
      const response = await emitAsync<ResponsePayload<TokenPayload>>(socket, 'account:authenticate', {
        accessToken: token.accessToken,
      });
      if (response.status === 'ok' && response.data) {
        dispatch(setAuthenticated(true));
      } else {
        dispatch(setAuthenticated(false));
        dispatch(setToken(null));
        localStorage.removeItem('token');
        window.location.reload();
      }
      dispatch(setLoading(false));
      return response;
    } else {
      dispatch(setLoading(false));
      return {
        status: 'error',
      };
    }
  };

export const initConnectionAsync = () => (dispatch: Dispatch<any>) => {
  dispatch(setLoading(true));

  const url = process.env.REACT_APP_SOCKET_SERVER_URL;
  if (!url) {
    throw new Error('REACT_APP_SOCKET_SERVER_URL is not set');
  }
  socket = io(url, {
    transports: ['websocket'],
    timeout: 10000,
    reconnectionDelay: 5000,
    reconnectionDelayMax: 5000,
  });

  // @ts-ignore
  window.socket = socket;

  socket.on('connect', async () => {
    dispatch(tryAuthenticateAsync());
  });

  socket.on('connect_error', () => {
    console.log('connect_error');
    dispatch(setLoading(true));
  });

  socket.on('disconnect', (reason: any) => {
    console.log('disconnect', reason);
    if (reason === 'io server disconnect') {
      dispatch(initConnectionAsync());
      dispatch(setAuthenticated(false));
    }
  });

  socket.on('reconnect', () => {
    console.log('reconnect');
    // dispatch(setLoading(false));
  });

  socket.on('error', console.error.bind(console));
};

export const selectConnection = (state: RootState): ConnectionState => {
  return state.connection;
};

export default connectionSlice.reducer;

export const emitSocket = async <T>(event: string, data: any): Promise<ResponsePayload<T>> => {
  return await emitAsync<ResponsePayload<T>>(socket, event, data);
};
