import { StopOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Layout, Row, Select, Table, Tooltip, Typography } from 'antd';
import { useAuthConnectionEffect } from 'hooks/useAuthConnectionEffect';
import { BackgroundJobPayload, JobState, JobStateMapping } from 'interfaces';
import moment, { type Moment } from 'moment/moment';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as BackgroundJobsSlice from 'slices/backgroundJobs';
import { useBackgroundJobs } from 'slices/backgroundJobs';
import * as CompaniesSlice from 'slices/companies';
import { useCompanies } from 'slices/companies';
import * as DriversSlice from 'slices/drivers';
import { useDrivers } from 'slices/drivers';
import { fuzzySelectFilter, getContainer } from 'utils/html';
import CreateBackgroundJobModal from './create';

const isJobStoppable = (job: BackgroundJobPayload) =>
  job.queue !== 'patch-queue' && job.state === 'active' && job.payload?.jobToken !== undefined && !job.stoppedAt;

const BackgroundJobsPage = () => {
  const [
    showModal,
    setShowModal,
  ] = useState<boolean>(false);
  const [
    companyId,
    setCompanyId,
  ] = useState<string | null>(null);
  const [
    driverId,
    setDriverId,
  ] = useState<string | null>(null);
  const [
    dateRange,
    setDateRange,
  ] = useState<{ from: Moment | null; to: Moment | null }>({
    from: null,
    to: null,
  });

  const { jobs, jobsLoading, jobsSubscribed } = useBackgroundJobs();
  const { companiesById, companiesLoading, companiesSubscribed } = useCompanies();
  const { driversById, driversSubscribed, driversLoading } = useDrivers(companyId);
  const dispatch = useDispatch();

  useAuthConnectionEffect(() => {
    dispatch(
      BackgroundJobsSlice.subscribe({
        companyId: companyId,
        driverId: driverId,
        from: dateRange.from,
        to: dateRange.to,
      })
    );
    return () => {
      dispatch(BackgroundJobsSlice.unsubscribe());
    };
  }, [
    companyId,
    driverId,
    dateRange.from,
    dateRange.to,
  ]);

  useAuthConnectionEffect(() => {
    dispatch(CompaniesSlice.subscribe());
    return () => {
      dispatch(CompaniesSlice.unsubscribe());
    };
  }, []);

  useAuthConnectionEffect(() => {
    if (companyId) {
      dispatch(DriversSlice.subscribe(companyId));
      return () => {
        dispatch(DriversSlice.unsubscribe(companyId));
      };
    }
  }, [
    companyId,
  ]);

  const isLoading = !jobsSubscribed || jobsLoading;

  const jobNames = useMemo(() => [...new Set(jobs.map((job) => job.name))], [jobs]);
  const queueNames = useMemo(() => [...new Set(jobs.map((job) => job.queue))], [jobs]);
  const jobStates = [
    ...Object.values(JobStateMapping),
    'stopped',
  ];

  return (
    <Layout className={'accounts-list-container'}>
      <Layout.Content>
        <Row justify={'space-between'}>
          <Col span={3}>
            <Typography.Title style={{ margin: '16px 0' }} level={3}>
              JOBS
            </Typography.Title>
          </Col>
          <Col span={18}>
            <Select<string, { label: string; value: string }>
              placeholder="Select company"
              showSearch
              allowClear
              getPopupContainer={getContainer}
              loading={companiesLoading}
              disabled={!companiesSubscribed || !jobsSubscribed || jobsLoading}
              options={Object.values(companiesById).map((company) => ({
                label: company.name,
                value: company._id,
              }))}
              value={companyId || undefined}
              onChange={(companyId) => {
                setCompanyId(companyId);
                setDriverId(null);
              }}
              filterOption={fuzzySelectFilter}
              style={{ minWidth: 150, margin: '1rem 0' }}
            />
            <Select<string, { label: string; value: string }>
              placeholder="Select driver"
              showSearch
              allowClear
              getPopupContainer={getContainer}
              loading={driversLoading}
              disabled={!driversSubscribed || !jobsSubscribed || jobsLoading}
              options={Object.values(driversById || {}).map((driver) => ({
                label: `${driver.firstName} ${driver.lastName}`,
                value: driver._id,
              }))}
              value={driverId || undefined}
              onChange={(driverId) => {
                setDriverId(driverId);
              }}
              filterOption={fuzzySelectFilter}
              style={{ minWidth: 150, margin: '1rem 0 1rem 1rem' }}
            />
            <DatePicker.RangePicker
              allowClear
              allowEmpty={[
                true,
                true,
              ]}
              disabled={!jobsSubscribed || jobsLoading}
              value={[
                dateRange.from,
                dateRange.to,
              ]}
              format="MMM DD, YYYY"
              onChange={(range) => {
                setDateRange({
                  from: range?.[0]?.utc(true)?.startOf('day') || null,
                  to: range?.[1]?.utc(true)?.startOf('day') || null,
                });
              }}
              getPopupContainer={getContainer}
              style={{ minWidth: 150, margin: '1rem 0 1rem 1rem' }}
            />
          </Col>
          <Col
            span={3}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Button key="createButton" type="primary" onClick={() => setShowModal(true)}>
              CREATE
            </Button>
            <CreateBackgroundJobModal isVisible={showModal} onCancel={() => setShowModal(false)} />
          </Col>
        </Row>
        <div className={'content'}>
          <Table<BackgroundJobPayload> loading={isLoading} dataSource={jobs} rowKey={(job) => job.id}>
            <Table.Column<BackgroundJobPayload>
              title="NAME"
              dataIndex="name"
              key="name"
              filters={jobNames.map((name) => ({
                value: name,
                text: name,
              }))}
              onFilter={(value, record) => typeof value === 'string' && record.name.indexOf(value) === 0}
            />
            \
            <Table.Column<BackgroundJobPayload>
              title="PATCH ID"
              dataIndex="payload"
              key="manualPatchId"
              render={(payload?: any) =>
                payload?.manualPatchId ? (
                  <a href={`/activity/${payload.manualPatchId}`}>{payload.manualPatchId}</a>
                ) : (
                  '-'
                )
              }
            />
            <Table.Column<BackgroundJobPayload>
              title="COMPANY"
              dataIndex="payload"
              key="company"
              render={(payload?: any) => payload?.companyName || '-'}
            />
            <Table.Column<BackgroundJobPayload>
              title="DRIVER"
              dataIndex="payload"
              key="driver"
              render={(payload?: any) => payload?.driverName || '-'}
            />
            <Table.Column<BackgroundJobPayload>
              title="FROM"
              dataIndex="payload"
              key="from"
              render={(payload?: any) => (payload?.from ? moment(payload.from).format('YYYY-MM-DD') : '-')}
            />
            <Table.Column<BackgroundJobPayload>
              title="TO"
              dataIndex="payload"
              key="to"
              render={(payload?: any) => (payload?.to ? moment(payload.to).format('YYYY-MM-DD') : '-')}
            />
            <Table.Column<BackgroundJobPayload>
              title="QUEUE"
              dataIndex="queue"
              key="queue"
              filters={queueNames.map((name) => ({
                value: name,
                text: name,
              }))}
              onFilter={(value, record) => typeof value === 'string' && record.queue.indexOf(value) === 0}
            />
            <Table.Column<BackgroundJobPayload>
              title="STATE"
              dataIndex="state"
              key="state"
              render={(state: JobState, record: BackgroundJobPayload) => {
                return isJobStoppable(record) ? (
                  <Tooltip title="The job is active. Attempt to stop it?">
                    <Button
                      onClick={() => dispatch(BackgroundJobsSlice.stopBackgroundJob(record.payload.jobToken))}
                      shape="circle"
                      icon={<StopOutlined />}
                      style={{ color: 'red' }}
                    />
                  </Tooltip>
                ) : record.stoppedAt ? (
                  'stopped'
                ) : (
                  JobStateMapping[state]
                );
              }}
              filters={jobStates.map((name) => ({
                value: name,
                text: name,
              }))}
              onFilter={(value, record) => {
                if (value === 'stopped') {
                  return record.stoppedAt !== null;
                }

                const key = Object.keys(JobStateMapping).find(
                  (key) => JobStateMapping[key as keyof typeof JobStateMapping] === value
                );
                return typeof value === 'string' && key !== undefined && record.state.indexOf(key) === 0;
              }}
            />
            <Table.Column<BackgroundJobPayload>
              title="SCHEDULED"
              dataIndex="scheduledAt"
              key="scheduledAt"
              render={(date?: string) => (date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '-')}
              sorter={(a, b, sortOrder) =>
                moment(a.scheduledAt || moment().add(sortOrder === 'ascend' ? 10 : -10, 'year')).diff(
                  b.scheduledAt || moment().add(sortOrder === 'ascend' ? 10 : -10, 'year')
                )
              }
            />
            <Table.Column<BackgroundJobPayload>
              title="FINISHED AT"
              dataIndex="finishedAt"
              key="finishedAt"
              render={(date?: string) => (date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '-')}
              sorter={(a, b, sortOrder) =>
                moment(a.finishedAt || moment().add(sortOrder === 'ascend' ? 10 : -10, 'year')).diff(
                  b.finishedAt || moment().add(sortOrder === 'ascend' ? 10 : -10, 'year')
                )
              }
            />
          </Table>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default BackgroundJobsPage;
