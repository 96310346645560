import { AlertPayload } from 'interfaces';
import moment, { Moment } from 'moment/moment';
import { Dispatch } from 'react';
import { useSelector } from 'react-redux';
import { createSubscriptiveSlice, ResponsePayload } from 'slices/subscriptive';
import { Socket } from 'socket.io-client';
import { RootState } from 'store/reducer';
import { v4 } from 'uuid';
import { emitAsync } from '../utils/socket';
import { setError } from './errors';

const {
  select,
  selectDictionary,
  unsubscribe,
  reducer,
  reconnect,
  onPublish,
  subscribe,
  selectResourceList,
  slice,
  initialState,
} = createSubscriptiveSlice({
  name: 'alerts',
  payloadType: AlertPayload,
  deletedFilterFn(resource): boolean {
    return resource.deletedAt != null;
  },
  reducers: {},
  idProp: 'clientResourceId',
});

export class CreateAlertPayload {
  clientResourceId: string;
  message: string;
  to?: string | null;
  companyId: string;
  driverId?: string | null;
}

const create =
  (alert: CreateAlertPayload) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<ResponsePayload<null | undefined>> => {
    const socket = getSocket();
    const clientResourceId = v4();
    const now = new Date();

    const optimisticAlert: AlertPayload = {
      ...alert,
      clientResourceId: alert.clientResourceId || clientResourceId,
      id: v4(),
      createdAt: now.toISOString(),
      updatedAt: now.toISOString(),
      to: alert.to !== null ? (alert.to as unknown as Moment)?.toISOString() : null,
      loading: true,
      deletedAt: null,
      companyName: 'N/A',
    };

    await dispatch(onPublish([optimisticAlert]));
    const response = await emitAsync<ResponsePayload<null | undefined>>(socket, `alerts:create`, optimisticAlert);
    if (response.status !== 'ok') {
      const now = new Date();
      dispatch(onPublish([{ ...optimisticAlert, deletedAt: now.toISOString() }]));
      dispatch(setError({ status: response.status, msg: response.msg }));
    }
    return response;
  };

export class UpdateAlertPayload extends CreateAlertPayload {
  id: string;
}

const update =
  (alert: UpdateAlertPayload, existingAlert: AlertPayload) =>
  async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket): Promise<ResponsePayload> => {
    const socket = getSocket();
    if (!!existingAlert) {
      const optimisticAlert: AlertPayload = {
        ...existingAlert,
        ...alert,
        to: alert.to !== null ? (alert.to as unknown as Moment)?.toISOString() : null,
        loading: true,
      };

      await dispatch(onPublish([optimisticAlert]));
      const response = await emitAsync<ResponsePayload>(socket, `alerts:update`, alert);
      if (response.status !== 'ok') {
        dispatch(onPublish([existingAlert]));
        dispatch(setError({ status: response.status, msg: response.msg }));
      }
      return response;
    }

    const error = {
      status: 'error',
      msg: 'Synchronization error',
    };
    dispatch(setError(error));

    return error;
  };

const deleteAlert =
  (existingAlert: AlertPayload) =>
  async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket): Promise<ResponsePayload> => {
    const socket = getSocket();
    if (!!existingAlert) {
      const optimisticAlert: AlertPayload = {
        ...existingAlert,
        deletedAt: moment().toISOString(),
        loading: true,
      };

      await dispatch(onPublish([optimisticAlert]));
      const response = await emitAsync<ResponsePayload>(socket, `alerts:delete`, {
        id: existingAlert.id,
      });
      if (response.status !== 'ok') {
        dispatch(onPublish([existingAlert]));
        dispatch(setError({ status: response.status, msg: response.msg }));
      }
      return response;
    }

    const error = {
      status: 'error',
      msg: 'Synchronization error',
    };
    dispatch(setError(error));

    return error;
  };

export default slice.reducer;

export { unsubscribe, reducer, reconnect, onPublish, subscribe, slice, initialState, create, update, deleteAlert };

export const useAlerts = () => {
  const { loading: alertsLoading, subscribed: alertsSubscribed } = useSelector(select);
  const alerts = useSelector(selectResourceList) as AlertPayload[];
  const alertsById = useSelector(selectDictionary) as Record<string, AlertPayload>;

  return {
    alerts,
    alertsById,
    alertsLoading,
    alertsSubscribed,
  };
};
