import { AccountPayload } from 'interfaces';
import { useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { ResponsePayload } from 'slices/subscriptive';
import { createSingleSubscriptiveSlice } from 'slices/subscriptive/single';
import { Socket } from 'socket.io-client';
import { RootState } from 'store/reducer';
import { emitAsync } from 'utils/socket';
import { setError } from './errors';

export class UpdateAccountPayload {
  username: string;
  name: string;
  password?: string;
  version: number;
}

const update =
  (account: UpdateAccountPayload) =>
  async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket): Promise<ResponsePayload> => {
    const myAccount = getState().myAccount.resource;
    const socket = getSocket();
    if (!!myAccount) {
      const response = await emitAsync<ResponsePayload>(socket, `myAccount:update`, account);

      if (response.status !== 'ok') {
        dispatch(setError({ status: response.status, msg: response.msg }));
      }

      return response;
    } else {
      const error = {
        status: 'error',
        msg: 'Synchronization error',
      };
      dispatch(setError(error));

      return error;
    }
  };

const logout =
  () =>
  async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket): Promise<ResponsePayload> => {
    const myAccount = getState().myAccount.resource;
    const socket = getSocket();
    if (!!myAccount) {
      const response = await emitAsync<ResponsePayload>(socket, `myAccount:logout`, {});

      if (response.status !== 'ok') {
        dispatch(setError({ status: response.status, msg: response.msg }));
      }

      return response;
    } else {
      const error = {
        status: 'error',
        msg: 'Synchronization error',
      };
      dispatch(setError(error));

      return error;
    }
  };

const { select, unsubscribe, reducer, onPublish, subscribe, selectResource, slice } = createSingleSubscriptiveSlice({
  name: 'myAccount',
  reducers: {},
  volatile: false,
});
export { unsubscribe, reducer, onPublish, subscribe, slice, logout, update };
const { setLoading } = slice.actions;

export default slice.reducer;

export const useMyAccount = () => {
  const { resource, loading, subscribed } = useSelector(select);
  return {
    myAccount: resource as AccountPayload | undefined,
    myAccountLoading: loading,
    myAccountSubscribed: subscribed,
  };
};
