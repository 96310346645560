import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppError } from 'interfaces';
import { RootState } from 'store/reducer';

interface ErrorState {
  error: AppError | null;
}

const initialState: ErrorState = {
  error: null,
};

export const errorsSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    setError: (state, { payload: error }: PayloadAction<{ status: string; msg?: string }>) => {
      state.error = {
        title: error.status === 'error' ? 'Error' : error.status,
        message: error.msg,
      };
    },
  },
});

export const { setError } = errorsSlice.actions;

export const selectErrors = (state: RootState): ErrorState => {
  return state.errors;
};

export default errorsSlice.reducer;
