import * as d3 from 'd3';
import { DependencyList, useLayoutEffect, useRef } from 'react';

export const useD3 = (
  renderFn: (svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>) => void | (() => void),
  deps: DependencyList
) => {
  const ref = useRef<SVGSVGElement>(null);

  useLayoutEffect(() => {
    return renderFn(d3.select(ref.current));
  }, deps);

  return ref;
};
