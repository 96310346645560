export const timezones: {
  CT: string;
  PT: string;
  MT: string;
  ET: string;
  UZ: string;
} = {
  ET: 'America/New_York',
  CT: 'America/Chicago',
  MT: 'America/Denver',
  PT: 'America/Los_Angeles',
  UZ: 'Asia/Tashkent',
};
