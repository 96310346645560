import { DatePicker, Form, message, Modal, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import moment, { Moment } from 'moment';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuthConnectionEffect } from '../../hooks/useAuthConnectionEffect';
import * as BackgroundJobsSlice from '../../slices/backgroundJobs';
import * as CompaniesSlice from '../../slices/companies';
import { useCompanies } from '../../slices/companies';
import * as DriversSlice from '../../slices/drivers';
import { useDrivers } from '../../slices/drivers';
import { useAppDispatch } from '../../store/store';
import { fuzzySelectFilter, getContainer } from '../../utils/html';

interface CreateDriverEventsCleanupJobPayload {
  companyId: string;
  driverId: string;
  from: string;
  to: string;
}

interface OwnProps {
  isVisible: boolean;
  onCancel: () => void;
}

const CreateBackgroundJobModal: FC<OwnProps> = ({ isVisible, onCancel }) => {
  const [
    companyId,
    setCompanyId,
  ] = useState<string | null>(null);
  const [form] = useForm<CreateDriverEventsCleanupJobPayload>();
  const { companiesById, companiesLoading, companiesSubscribed } = useCompanies();
  const { driversById, driversSubscribed, driversLoading } = useDrivers(companyId);
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();

  useAuthConnectionEffect(() => {
    dispatch(CompaniesSlice.subscribe());
    return () => {
      dispatch(CompaniesSlice.unsubscribe());
    };
  }, []);

  useAuthConnectionEffect(() => {
    if (companyId) {
      dispatch(DriversSlice.subscribe(companyId));
      return () => {
        dispatch(DriversSlice.unsubscribe(companyId));
      };
    }
  }, [
    companyId,
  ]);

  return (
    <Modal
      visible={isVisible}
      title="CREATE JOB: DELETE DIAGNOSTIC EVENTS"
      okText="CREATE"
      cancelText="CANCEL"
      onCancel={onCancel}
      onOk={() => {
        form.submit();
      }}
      confirmLoading={companiesLoading || !companiesSubscribed}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        onFinish={async (values: CreateDriverEventsCleanupJobPayload) => {
          const { status, msg } = await appDispatch(
            BackgroundJobsSlice.createDriverEventsRemovalJob(values.companyId, values.driverId, values.from, values.to)
          );
          if (status === 'ok') {
            form.resetFields();
            onCancel();
            message.success('Job has been created');
          } else {
            message.error(msg || 'Error');
          }
        }}
      >
        <Form.Item
          name="companyId"
          label="COMPANY"
          className="collection-create-form_last-form-item"
          rules={[
            {
              required: true,
              message: 'Please choose company',
            },
          ]}
        >
          <Select<string, { label: string; value: string }>
            placeholder="Select company"
            showSearch
            getPopupContainer={getContainer}
            loading={companiesLoading}
            disabled={!companiesSubscribed}
            options={Object.values(companiesById).map((company) => ({
              label: company.name,
              value: company._id,
            }))}
            value={companyId || undefined}
            onChange={(companyId) => {
              setCompanyId(companyId);
              form.setFields([{ name: 'driverId', value: null }]);
            }}
            filterOption={fuzzySelectFilter}
            style={{ minWidth: 300 }}
          />
        </Form.Item>
        <Form.Item
          name="driverId"
          label="DRIVER"
          className="collection-create-form_last-form-item"
          rules={[
            {
              required: true,
              message: 'Please choose driver',
            },
          ]}
        >
          <Select<string, { label: string; value: string }>
            placeholder="Select driver"
            showSearch
            getPopupContainer={getContainer}
            loading={driversLoading}
            disabled={!driversSubscribed}
            options={Object.values(driversById || {}).map((driver) => ({
              label: `${driver.firstName} ${driver.lastName}`,
              value: driver._id,
            }))}
            filterOption={fuzzySelectFilter}
            style={{ minWidth: 300 }}
          />
        </Form.Item>
        <Form.Item name="from" label="From" hidden />
        <Form.Item name="to" label="To" hidden />
        <Form.Item
          name="range"
          label="PERIOD"
          rules={[
            {
              required: true,
              message: 'Choose date period',
            },
            {
              validator: (_, range: [Moment | null, Moment | null] | null) => {
                return range?.[0] && range?.[1] && range[1].diff(range[0], 'days') <= 10
                  ? Promise.resolve()
                  : Promise.reject(new Error('Up to 10 days range is allowed.'));
              },
            },
          ]}
        >
          <DatePicker.RangePicker
            format="MMM DD, YYYY"
            onChange={(value) => {
              const [
                from,
                to,
              ] = value || [];
              form.setFields([
                {
                  name: 'from',
                  value: from ? moment.utc(from.format('YYYY-MM-DD')).toDate() : null,
                },
                {
                  name: 'to',
                  value: to ? moment.utc(to.format('YYYY-MM-DD')).toDate() : null,
                },
              ]);
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateBackgroundJobModal;
