import { Tooltip, TooltipProps } from 'antd';
import React from 'react';

type ConditionalTooltipProps = {
  condition: boolean;
} & TooltipProps;
const ConditionalTooltip: React.FC<ConditionalTooltipProps> = ({ condition, children, ...tooltipProps }) => {
  // We need explicit fragment for children, due to https://github.com/DefinitelyTyped/DefinitelyTyped/issues/18051,
  // or we can even not use FC<Props> altogether: https://kentcdodds.com/blog/how-to-write-a-react-component-in-typescript
  return condition ? <Tooltip {...tooltipProps}>{children}</Tooltip> : <>{children}</>;
};

export default ConditionalTooltip;
