import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { getSocket } from 'slices/connection';
import { reducers } from 'store/reducer';

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: getSocket,
      },
      serializableCheck: false,
    }),
});
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;
